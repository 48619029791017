<template>
  <div class="codeOfConduct">
    <!-- Menu Icon for small screens -->
    <div class="menu-icon" @click="toggleSidebar">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Sidebar Toggle Button for Smaller Screens -->
    <button v-if="isSmallScreen" class="show-sidebar-btn-small" data-bs-toggle="collapse"
      data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample"></button>

    <!-- Sidebar -->
    <div id="sidebar-1" class="sidebar" :class="{ collapsed: sidebarCollapsed }">
      <div class="logo">Secondlifeasia Admin</div>
      <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
      <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
      <button class="sidebar-button" @click="onMyLeavesClick">Staff on Leave</button>
      <button class="sidebar-button" @click="onLeavesToApproveClick">Leaves to Approve</button>
      <button class="sidebar-button" @click="onStaffClick">Staff Management</button>
      <button class="sidebar-button" @click="onCodeOfConductClick">Employee Handbook</button>
      <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">
        Logout
      </button>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <!-- Control Panel -->
      <div class="title"><b>Employee Handbook</b></div>

      <!-- PDF Viewer -->
      <pdf v-if="showPdf" :src="pdfSource" :page="1" @num-pages="onLoadSuccess"></pdf>

      <!-- Toggle PDF Visibility Button -->
      <button @click="togglePdfVisibility">Toggle PDF Visibility</button>
    </div>
  </div>
</template>
  
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GgAdminDb",
  data() {
    return {
      sidebarCollapsed: false,
      isSmallScreen: window.innerWidth <= 768,
      showPdf: true,
      pdfSource: "https://dpp-object-space.sgp1.digitaloceanspaces.com/res/Employee%20Handbook%202024.pdf",
    };
  },

  methods: {
    onDashBoardClick() {
      this.$router.push("/slaAdmindb");
    },
    onCalendarClick() {
      this.$router.push("/slaAdminCalendar");
    },
    onMyLeavesClick() {
      this.$router.push("/slaAdminMyLeaves");
    },
    onLeavesToApproveClick() {
      this.$router.push("slaAdminLeavesToApprove");
    },
    onStaffClick() {
      this.$router.push("/slaAdminStaff");
    },
    onCodeOfConductClick() {
      this.$router.push("/codeOfConduct");
    },
    onPendingLeavesClick() {
      // Handle pending leaves click
    },
    onLeaveLogsClick() {
      // Handle leave logs click
    },
    onBalanceClick() {
      // Handle balance click
    },
    onLogoutClick() {
      this.$router.push("/");
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    togglePdfVisibility() {
      //console.log("Toggle PDF Visibility clicked");
      //console.log("Current value of showPdf:", this.showPdf);
      //this.showPdf = !this.showPdf;
      //console.log("Updated value of showPdf:", this.showPdf);
      const externalLink = "https://dpp-object-space.sgp1.digitaloceanspaces.com/res/Employee%20Handbook%202024.pdf";
      window.location.href = externalLink;




    },

    onLoadSuccess(pages) {
      console.log("PDF loaded successfully with", pages, "pages");
    },
    handleResize() {
      this.isSmallScreen = window.innerWidth <= 768;
    },
  },

  mounted() {
    console.log("Component mounted");
    window.addEventListener("resize", this.handleResize);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
});
</script>

  
<style scoped>
/* Add your styles here */
element.style {
  height: 100vh;
}

.codeOfConduct {
  display: flex;
  overflow: hidden;
  /* Hide the sidebar when collapsed */
}

.menu-icon {
  display: none;
  /* Initially hide the menu icon for larger screens */
  cursor: pointer;
}



.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.sidebar {
  width: 250px;
  background-color: #000;
  color: #000;
  padding: 20px;
  padding-right: 0%;
  display: flex;
  flex-direction: column;
  transition: 0.4s;
  background: #1e1e0eb2;
}

.title {
  padding-bottom: 5px;
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.40);
  text-align: center;
  font-family: Century Gothic;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebar.collapsed {
  width: 0;
  overflow: hidden;
}

.logo {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fbfadc85;
  text-align: center;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-right: 25px;
}

.sidebar-button {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #555;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  border-radius: 10px 0px 0px 10px;
  background: #000;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 100;
  color: #fbfadca4;
  line-height: normal;
  text-align: left;
  text-indent: 20px;
}

.sidebar-button.logout-btn {
  /* Style for logout button */
  cursor: pointer;
  margin-top: auto;
  /* Push logout button to the bottom */
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100px;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
  text-indent: 13px;
}

/* Button to bring sidebar back in for smaller screens */
.show-sidebar-btn-small {
  position: fixed;
  top: 0;
  left: 5px;
  width: 25px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  display: block;
  background-color: rgba(0, 0, 0, 0.30);
  border: 0px;
}

/* Button to bring sidebar back in for larger screens */
.show-sidebar-btn {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
  display: block;
}

.main-content {
  flex: 1;
  padding: 20px;
  background: #FBFADC;
  backdrop-filter: blur(5px);
  overflow-y: auto;
  height: 100vh;
}

.control-panel {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.control-tile {
  flex: 1;
  padding: 15px;
  /* here is where it changes once i start edditing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.30);
}

.custom-calendar {
  width: 100%;
  /* Set the desired width */
  height: 500px;

  /* Set the desired height */
}


.calendar {
  flex: 1;
  padding: 25px;
  /* here is where it changes once i start edditing tiles */
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgb(0 0 0);

}


/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 0;
    overflow: hidden;
    padding: 0%;
    transition: 0.2s;
    flex-direction: column;
    display: flex;
  }


  .menu-icon {
    display: none;
  }

  /* Hide the show-sidebar-btn on small screens when sidebar is visible */
  .show-sidebar-btn {
    display: none;
    color: #00000060;
  }

  .calendar {
    flex: 1;
    padding: 25px;
    /* here is where it changes once i start edditing tiles */
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 15px;
    background: rgba(0, 0, 0);
  }

}
</style>
