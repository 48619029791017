<template>
  <div class="slaStaffMyLeaves">
    <!-- Menu Icon for small screens -->
    <div class="menu-icon" @click="toggleSidebar">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Sidebar Toggle Button for Smaller Screens -->
    <button v-if="isSmallScreen" class="show-sidebar-btn-small" @click="toggleSidebar"></button>

    <!-- Sidebar -->
    <div class="sidebar" :class="{ 'collapsed': sidebarCollapsed }">
      <div class="logo">Secondlifeasia Staff</div>
      <button class="sidebar-button" @click="onDashBoardClick">Dashboard</button>
      <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
      <button class="sidebar-button" @click="onMyLeavesClick">My Leaves</button>
      <button class="sidebar-button" @click="onCodeOfConductClick">Employee Handbook</button>
      <button class="sidebar-button" @click="onMyProfileClick">My Profile</button>
      <!-- Styled the logout button as well -->
      <button v-b-toggle.sidebar-1 class="sidebar-button logout-btn" @click="onLogoutClick">Logout</button>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <!-- Search bar here -->
      
      <!-- Dynamic Table -->
      <div class="table-Style">
        <table class="table table-striped table-hover table-hover">
          <thead>
            <tr>
              <th scope="col">Leave Type</th>
              <th scope="col">Status</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
            </tr>
          </thead>
          <tbody>
            <!-- Loop through each item in staffData and populate the table dynamically -->
            <tr v-for="(item, index) in staffData" :key="index">
              <td>{{ item.type }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.startDate }}</td>
              <td>{{ item.endDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "slaStaffMyLeaves",
  data() {
    return {
      sidebarCollapsed: false,
      isSmallScreen: window.innerWidth <= 768, // Check if the initial screen size is small
      staffData: [
        { type: 'ML', status: 'Pending', startDate: '06, JAN, 2023', endDate: '07, JAN, 2023' },
        { type: 'EL', status: 'Approved', startDate: '14, JAN, 2023', endDate: '18, JAN, 2023' },
        { type: 'AL', status: 'Denied', startDate: '21, JAN, 2023', endDate: '30, JAN, 2023' },
        // Add more data as needed
      ],
    };
  },

  methods: {
    onDashBoardClick() {
      this.$router.push("/slastaffdb");
    },
    onCalendarClick() {
      this.$router.push("/slaStaffCalendar");
    },
    onMyLeavesClick() {
      // Replace with your actual route for My Leaves
      this.$router.push("/slaStaffMyLeaves");
    },
        onCodeOfConductClick() {
            const externalLink = "https://dpp-object-space.sgp1.digitaloceanspaces.com/res/Employee%20Handbook%202024.pdf";
            window.open(externalLink, '_blank');
        },    
    onPendingLeavesClick() {
      // Handle pending leaves click
    },
    onLeaveLogsClick() {
      // Handle leave logs click
    },
    onBalanceClick() {
      // Handle balance click
    },
    onMyProfileClick(){
        this.$router.push("/slaStaffMyProfile");
    },
    onLogoutClick() {
      this.$router.push("/");
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    handleResize() {
      this.isSmallScreen = window.innerWidth <= 768;
    },
  },

  mounted() {
    // Add an event listener to handle window resize
    window.addEventListener("resize", this.handleResize);
  },

  beforeUnmount() {
    // Remove the event listener to avoid memory leaks
    window.removeEventListener("resize", this.handleResize);
  },
});
</script>

<style scoped lang="scss">
    @import '../styles/styles.scss';
  </style>
