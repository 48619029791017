<template>
  <div class="slaAdminLeavesToApprove">
    <!-- Menu Icon for small screens -->
    <div class="menu-icon" @click="toggleSidebar">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Sidebar Toggle Button for Smaller Screens -->
    <button
      v-if="isSmallScreen"
      class="show-sidebar-btn-small"
      @click="toggleSidebar"
    ></button>

    <!-- Sidebar -->
    <div class="sidebar" :class="{ collapsed: sidebarCollapsed }">
      <div class="logo">Secondlifeasia Admin</div>
      <button class="sidebar-button" @click="onDashBoardClick">
        Dashboard
      </button>
      <button class="sidebar-button" @click="onCalendarClick">Calendar</button>
      <button class="sidebar-button" @click="onMyLeavesClick">
        Staff on Leave
      </button>
      <button class="sidebar-button" @click="onLeavesToApproveClick">
        Leaves to Approve
      </button>
      <button class="sidebar-button" @click="onDashBoardClick2">
        Leave to Apply
      </button>
      <button class="sidebar-button" @click="onStaffClick">
        Staff Management
      </button>
      <button class="sidebar-button" @click="onCodeOfConductClick">
        Employee Handbook
      </button>
      <!-- Styled the logout button as well -->
      <button
        v-b-toggle.sidebar-1
        class="sidebar-button logout-btn"
        @click="onLogoutClick"
      >
        Logout
      </button>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <div class="table-Style">
        <!-- Add a spinner that is conditionally displayed -->
        <div
          v-if="isProcessing"
          class="spinner-border text-light"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>

        <!-- Display the table when not processing -->
        <table v-if="!isProcessing" class="table table-striped table-hover">
          <!-- Table Headings -->
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Department</th>
              <th scope="col">Leave Type</th>
              <th scope="col">Action</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
            </tr>
          </thead>
          <!-- Table Body -->
          <tbody>
            <tr v-for="(item, index) in paginatedTableData" :key="index">
              <td>{{ item.username }}</td>
              <td>{{ item.department }}</td>
              <td>{{ item.leave_type }}</td>
              <td>
                <!-- Dropdown Button -->
                <div class="dropdown">
                  <button
                    class="btn btn-secondary btn-sm dropdown-toggle actionbtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    :id="'dropdown' + index"
                    v-if="item.status === 'Pending'"
                  >
                    Action
                  </button>
                  <button
                    class="btn btn-secondary btn-sm dropdown-toggle actionbtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    :id="'dropdown' + index"
                    v-else-if="item.status === 'Approved'"
                  >
                    Approve
                  </button>
                  <button
                    class="btn btn-secondary btn-sm dropdown-toggle actionbtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    :id="'dropdown' + index"
                    v-else-if="item.status === 'Denied'"
                  >
                    Deny
                  </button>

                  <!-- Dropdown Menu -->
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item approve"
                        href="#"
                        style="color: green"
                        @click="
                          onDropdownItemClick(
                            'Approve',
                            'dropdown' + index,
                            $event,
                            item.request_ticket
                          )
                        "
                        >Approve</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item deny"
                        href="#"
                        style="color: red"
                        @click="
                          onDropdownItemClick(
                            'Deny',
                            'dropdown' + index,
                            $event,
                            item.request_ticket
                          )
                        "
                        >Deny</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
              <td>{{ item.start_date }}</td>
              <td>{{ item.end_date }}</td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination Controls -->
        <div
          class="pagination-controls"
          style="text-align: center; margin-top: 20px"
        >
          <button @click="nextPage" :disabled="currentPage === totalPages">
            Next
          </button>
          <button @click="prevPage" :disabled="currentPage === 1">
            Previous
          </button>

          <strong>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
          </strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getRequestList, requestAuthorise } from "@/api";

export default defineComponent({
  name: "slaAdminLeavesToApprove",
  data() {
    return {
      sidebarCollapsed: false,
      isSmallScreen: window.innerWidth <= 768,
      tableData: [],
      isProcessing: false,
      currentPage: 1,
      pageSize: 10, // New property to indicate if the request is being processed
    };
  },

  computed: {
    totalPages() {
      // Calculate the total number of pages based on tableData
      return Math.ceil(this.tableData.length / this.pageSize);
    },
    paginatedTableData() {
      // Calculate paginated data based on current page and page size
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.tableData.slice(start, end);
    },
  },

  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    onDashBoardClick() {
      this.$router.push("/slaAdmindb");
    },
    onDashBoardClick2() {
      this.$router.push("/slaadminleaveapply");
    },
    onCalendarClick() {
      this.$router.push("/slaAdminCalendar");
    },
    onMyLeavesClick() {
      this.$router.push("/slaAdminMyLeaves");
    },
    onLeavesToApproveClick() {
      this.$router.push("slaAdminLeavesToApprove");
    },
    onStaffClick() {
      this.$router.push("/slaAdminStaff");
    },
    onCodeOfConductClick() {
      const externalLink =
        "https://dpp-object-space.sgp1.digitaloceanspaces.com/res/Employee%20Handbook%202024.pdf";
      window.open(externalLink, "_blank");
    },
    onLogoutClick() {
      this.$router.push("/");
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    onDropdownItemClick(action, dropdownId, event, ticket) {
      // Set isProcessing to true to show the spinner
      this.isProcessing = true;
      this.updateButton(action, dropdownId);
      this.setButtonColor(event.target, action);
      this.adminautho(action, ticket)
        .then(() => {
          // Reset isProcessing when the request is completed
          this.isProcessing = false;
        })
        .catch(() => {
          // Handle error if the request fails
          this.isProcessing = false;
        });
      console.log(ticket);
      console.log(action);
    },

    updateButton(action, dropdownId) {
      const button = document.getElementById(dropdownId);
      if (button) {
        button.textContent = action;
      }
    },

    async adminaction() {
      try {
        const formData = localStorage.sessionid;

        const response = await getRequestList(formData);

        console.log("Leave Request Submitted:", response);
        this.tableData = response.data;
      } catch (error) {
        console.error("Leave Request Error:", error);
        // Handle leave request error (e.g., show error message to the user)
      }
    },

    async adminautho(status, ticket) {
      try {
        const formData = {
          status: status,
          request_ticket: ticket,
          session_id: localStorage.sessionid,
        };

        const response = await requestAuthorise(formData);

        console.log("Leave Request Submitted:", response);
        //this.tableData = response.data
        this.adminaction();
      } catch (error) {
        console.error("Leave Request Error:", error);
        // Handle leave request error (e.g., show error message to the user)
      }
    },

    setButtonColor(button, action) {
      // Define the mapping between actions and button classes
      const colorMap = {
        Deny: ["btn-danger", "btn-deny"],
        Approve: ["btn-success", "btn-approve"],
      };

      // Remove all existing button classes
      button.className = button.className
        .split(" ")
        .filter(
          (className) =>
            !colorMap.Deny.includes(className) &&
            !colorMap.Approve.includes(className)
        )
        .join(" ");

      // Add the appropriate classes based on the action
      if (colorMap[action]) {
        button.classList.add(...colorMap[action]);
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.adminaction();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },

  watch: {
    isSmallScreen(newVal) {
      if (newVal) {
        this.sidebarCollapsed = true;
      }
    },
  },
});
</script>

<style scoped lang="scss">
/* Add your styles here */
.slaAdminLeavesToApprove {
  display: flex;
  overflow: hidden;
  /* Hide the sidebar when collapsed */
}

.dropdown-menu {
  position: absolute;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.menu-icon {
  display: none;
  /* Initially hide the menu icon for larger screens */
  cursor: pointer;
}

.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.sidebar {
  width: 250px;
  background-color: #000;
  color: #000;
  padding: 20px;
  padding-right: 0%;
  /* Set sidebar height to full viewport height */
  display: flex;
  flex-direction: column;
  transition: 0.4s;
  /* Add transition for smoother animation */
  background: #1e1e0eb2;
}

.title {
  padding-bottom: 20px;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  font-family: Century Gothic;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebar.collapsed {
  width: 0;
  overflow: hidden;
}

.logo {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fbfadc85;
  text-align: center;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-right: 25px;
}

.sidebar-button {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #555;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  border-radius: 10px 0px 0px 10px;
  background: #000;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: 100;
  color: #fbfadca4;
  line-height: normal;
  text-align: left;
  text-indent: 20px;
}

.sidebar-button.logout-btn {
  /* Style for logout button */
  cursor: pointer;
  margin-top: auto;
  /* Push logout button to the bottom */
  border-radius: 10px;
  background: #000;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100px;
  flex-shrink: 0;
  font-family: Century Gothic;
  color: #fbfadca4;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
  text-indent: 13px;
}

/* Button to bring sidebar back in for smaller screens */
.show-sidebar-btn-small {
  position: fixed;
  top: 10px;
  left: 5px;
  width: 25px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  border: 0px;
}

/* Button to bring sidebar back in for larger screens */
.show-sidebar-btn {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
  display: block;
}

.main-content {
  flex: 1;
  padding: 20px;
  background: #fbfadc;
  backdrop-filter: blur(5px);
  overflow-y: scroll;
  height: 100vh;
}

.table-Style {
  flex: 1;
  padding: 25px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  margin-top: 90px;
  font-size: 14px;
  /*overflow-y: auto;*/
}

.control-panel {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.control-tile {
  flex: 1;
  padding: 70px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.3);
}

.actionbtn {
  flex: 1;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0px;
  cursor: pointer;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
}

/* Custom styles for button colors */
.actionbtn.btn-approve {
  background-color: green !important;
}

.actionbtn.btn-deny {
  background-color: red !important;
}

.calendar {
  flex: 1;
  padding: 75px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.3);
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 0;
    overflow: hidden;
    padding: 0%;
  }

  .menu-icon {
    display: none;
  }

  .show-sidebar-btn {
    display: none;
    color: #00000060;
  }

  .table-Style {
    flex: 1;
    padding: 25px;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    margin-top: 20px;
  }

  .actionbtn {
    padding: 5px;
    margin-top: 0px;
  }

  .calendar {
    padding: 15px;
    margin-bottom: 5px;
  }

  .table-Style {
    font-size: 14px;
    padding: 10px;
  }
}

@import "../styles/styles.scss";
</style>
